import React, { useState, useEffect, useRef } from 'react';
import { Card } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import {
  ChartImageComponent,
  GridComponent,
  PieChartComponent,
} from '../../../Utils/FormatMessage/FormatMessage'; // Importing the new components
import {
  buildNotification,
  ErrorFallback,
  GenerateNotification,
  Loader,
} from 'smart-react';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';
import { getAssistantByAssistantId } from '../../Assistant/Services/AiAssistantService';
import {
  sendBYMessage,
  getSessionGridData,
} from '../../SmartChat/Services/BlueYonderChatService';
import FormatMessageContent from '../../../Utils/Filters/FormatMessageContent';
import { Switch } from '@progress/kendo-react-inputs';
import { useParams } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import './SmartBot.scss';
import { CUSTOM_AGENT_WELCOME_MESSAGE } from '../../../constants/applicationConstants';
import { useLocation } from 'react-router-dom';

/**
 * Component for managing chat with AI assistants.
 * @returns {JSX.Element} SmartChat component.
 */

const SmartCustomBot = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [sending, setSending] = useState(false);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const chatWindowRef = useRef(null);
  const [showPromptOverlay, setShowPromptOverlay] = useState(false); // State to control prompt overlay
  const [prompts, setPrompts] = useState([]); // State to store prompts
  const [streamingResponse, setStreamingResponse] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const location = useLocation(); // Get the current location object
  const queryParams = new URLSearchParams(location.search); // Parse the query string
  const theme = queryParams.get('theme'); // Extract the `theme` parameter if it exists

  // Get the id and session_id parameter from the URL
  const { id, session_id } = useParams();

  /**
   * On Page Load Set Welcome Message and Fetch Session Data
   */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoader(true);
      try {
        if (id) {
          await fetchAssistantWithAssistantId(id);
        }
        setWelcomeMessage();
        await fetchSessionGridData(session_id);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoader(false);
      }
    };

    fetchData();
  }, [id, session_id]); // Include dependencies if needed

  /**
   * Resets the chat to its initial state.
   */
  const handleResetChat = () => {
    setIsLoader(false);
    setSending(false);
    setMessages([]);
    setNewMessage('');
    setShowPromptOverlay(false);
    setWelcomeMessage();
    fetchSessionGridData(session_id);
  };

  /**
   * Set Welcome Message.
   */
  const setWelcomeMessage = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: 'assistant',
        text: CUSTOM_AGENT_WELCOME_MESSAGE,
      },
    ]);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleStreamingResponse = () => {
    setStreamingResponse(!streamingResponse); // Toggle includeFiles state
  };
  /**
   * Fetches the list of assistants.
   */
  const fetchAssistantWithAssistantId = async ($id) => {
    setIsLoader(true);
    try {
      const response = await getAssistantByAssistantId($id);
      if (response?.isSuccess) {
        setAssistants([...assistants, response.data]);
        setSelectedAssistant(response.data);
      } else {
        GenerateNotification(
          buildNotification({
            title: 'Error fetching assistants',
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error fetching assistants: ' + error,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setIsLoader(false);
    }
  };

  /**
   * Submits the message on key press (Enter).
   * @param {Object} event - Event object.
   */
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!sending || !loadingData) {
        handleMessageSend();
      }
    }
  };

  /**No data available!


   * Handles the selection of a prompt and hides the prompt overlay.
   * @param {string} prompt - Selected prompt.
   */
  const handlePromptSelection = (prompt) => {
    if (!sending) {
      handleMessageSend(prompt);
      setShowPromptOverlay(false);
    }
  };

  const fetchSessionGridData = async (id) => {
    setLoadingData(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: 'assistant',
        text: 'Fetching data, please wait...',
        type: 'loading',
      },
    ]);

    try {
      const response = await getSessionGridData(id);

      // Remove the loading message
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.type !== 'loading')
      );
      // Add the grid data to chat
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          text: '',
          type: 'Grid',
          data: { metadata: response?.metadata, values: response?.values },
        },
      ]);

      // Show suggested prompts if available
      if (response?.questions) {
        setPrompts(response?.questions);
        setShowPromptOverlay(true);
      }
      if (!response?.error) {
        setLoadingData(false);
      }
    } catch (error) {
      // Remove loading message and show error message
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.type !== 'loading')
      );
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          text: 'Error fetching data, please try again later.',
          type: 'error',
        },
      ]);

      GenerateNotification(
        buildNotification({
          title: 'Error fetching Grid Data: ' + error,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    }
  };

  /**
   * Handles selection of an assistant.
   * @param {Object} event - Event object.
   */
  const handleAssistantSelect = async (event) => {
    const assistant = assistants.find(
      (assistant) => assistant.id === parseInt(event)
    );
    handleResetChat();
    setSelectedAssistant(assistant);
    if (assistant.type === 'OpenAI') {
      await fetchPrompts(assistant);
    }
  };

  /**
   * Handles sending of a message.
   */
  const handleAssistantNotSelected = () => {
    if (!selectedAssistant || selectedAssistant.id === '') {
      GenerateNotification(
        buildNotification({
          title: 'Please select an assistant!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
      return true;
    }
    return false;
  };

  const handleCustomMessageFlow = async (prompt) => {
    setShowPromptOverlay(false);
    if (handleAssistantNotSelected()) return;
    const trimmedNewMessage = newMessage.trim();
    const trimmedPrompt = Array.isArray(prompt) ? prompt[0] : prompt.trim();
    if (trimmedNewMessage.length === 0 && trimmedPrompt.length === 0) return;
    const message = trimmedNewMessage ? trimmedNewMessage : trimmedPrompt;
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', text: message },
    ]);
    setNewMessage('');
    setSending(true);

    const data = {
      command: message,
      session_key: session_id,
    };
    const response = await sendBYMessage(data);
    setSending(false);
    if (response && response?.status_code === 200) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          text: response?.message,
          type: response?.type,
          data: response?.payload,
          attachment: response?.attachemnt,
        },
      ]);
      setSending(false);
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          text: 'There is some error!',
          type: response?.type,
        },
      ]);
      setSending(false);
    }
  };

  const handleMessageSend = async (prompt = '') => {
    if (selectedAssistant?.type === 'Custom') {
      await handleCustomMessageFlow(prompt);
    }
  };

  /**
   * Scrolls to the bottom of the chat window.
   */
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div
        className={`bot-chat-container smart-custom-bot k-bg-white ${
          theme === 'blueyonder' ? 'blue-yonder-theme' : ''
        }`}
      >
        <React.Suspense fallback={<Loader />}>
          {isLoader && <Loader />}
          {selectedAssistant?.name && (
            <Card className="assistant-selection k-mt-1">
              <div className="k-d-flex k-justify-content-between custom-bot-header">
                <p>
                  <span className="assistant-name k-display-flex k-align-items-center">
                    {selectedAssistant?.name && assistants.length > 1 && (
                      <>
                        <select
                          className="custom-select k-ml-1"
                          value={selectedAssistant.id}
                          onChange={(event) =>
                            handleAssistantSelect(event.target.value)
                          }
                        >
                          {assistants.map((assistant, index) => (
                            <option key={assistant.id} value={assistant.id}>
                              {assistant.name}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                    {selectedAssistant?.name && assistants.length <= 1 && (
                      <span className="selected-assistant assistant-name-custom">
                        {selectedAssistant.name}
                      </span>
                    )}
                    {!sending && (
                      <span
                        className="k-button-icon k-font-icon k-i-arrow-rotate-cw-small !k-font-size-xl delete-button k-cursor-pointer reset-button k-ml-1"
                        onClick={handleResetChat}
                      />
                    )}
                  </span>
                </p>
                {(selectedAssistant?.type === 'OpenAI' ||
                  selectedAssistant?.type === 'SmartAI') && (
                  <div className="streaming-response-switch k-mb-2 k-mr-2 k-float-right">
                    <span className="k-mr-2 k-font-size-sm">Streaming</span>
                    <Switch
                      checked={streamingResponse}
                      onChange={handleStreamingResponse}
                    />
                  </div>
                )}
              </div>
            </Card>
          )}
          <Card className="chat-box">
            <div className="bot-chat-window" ref={chatWindowRef}>
              {messages &&
                messages.slice(0).map((message, index) => (
                  <div
                    key={index}
                    className={`message ${message.role === 'user' ? 'user-message' : 'assistant-message'} ${message.type ? ` otherdata ${message.type}` : ''}`}
                  >
                    {message.type === 'Grid' ? (
                      // Render table if the type is Grid
                      <>
                        <p>{message?.text}</p>
                        <GridComponent
                          metadata={message?.data?.metadata}
                          values={message?.data?.values}
                        />
                      </>
                    ) : message.type === 'Pie_Chart' ? (
                      // Render pie chart if the type is PieChart
                      <>
                        <p>{message?.text}</p>
                        <PieChartComponent
                          metadata={message?.data?.metadata}
                          values={message?.data?.values}
                        />
                      </>
                    ) : message.type === 'Chart' ? (
                      <>
                        <p>{message?.text}</p>
                        <ChartImageComponent
                          imageContent={message?.attachment?.content}
                          imageType={message?.attachment?.type}
                        />
                      </>
                    ) : (
                      // Otherwise, render the message content
                      <FormatMessageContent markdownContent={message.text} />
                    )}
                  </div>
                ))}
              {showPromptOverlay && (
                <div className="prompt-cards">
                  {prompts.map((prompt, index) => (
                    <div
                      key={index}
                      className="prompt-card"
                      onClick={() => handlePromptSelection(prompt)}
                    >
                      <span>{prompt}</span>
                      <button className="delete-button k-float-right k-cursor-pointer">
                        <span className="fas fa-paper-plane" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {sending && <div className="custom-dots k-ml-3" />}
            <div className="bot-message-input">
              <input
                type="text"
                value={newMessage}
                onKeyPress={handleKeyPress}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
              />
              <Button
                className="ai-button-primary"
                onClick={() => {
                  if (!sending && !loadingData) {
                    handleMessageSend();
                  }
                }}
                disabled={sending}
              >
                {!sending ? (
                  <span className="fas fa-paper-plane" />
                ) : (
                  <span className="k-text-white">...</span>
                )}
              </Button>
            </div>
          </Card>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};

export default SmartCustomBot;
