import React, { useState, useEffect, useRef } from 'react';
import { Card } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import {
  ChartImageComponent,
  GridComponent,
  PieChartComponent,
  ProgressCard,
} from '../../../Utils/FormatMessage/FormatMessage'; // Importing the new components
import {
  buildNotification,
  ErrorFallback,
  GenerateNotification,
  Loader,
} from 'smart-react';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';
import { getAssistantByAssistantId } from '../../Assistant/Services/AiAssistantService';
import {
  sendBYMessage,
  getSessionGridData,
  getStatus,
} from '../../SmartChat/Services/BlueYonderChatService';
import FormatMessageContent from '../../../Utils/Filters/FormatMessageContent';
import { Switch } from '@progress/kendo-react-inputs';
import { useParams } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import './SmartBot.scss';
import { CUSTOM_AGENT_WELCOME_MESSAGE } from '../../../constants/applicationConstants';
import { useLocation } from 'react-router-dom';

/**
 * Component for managing chat with AI assistants.
 * @returns {JSX.Element} SmartChat component.
 */

const SmartCustomBot = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [sending, setSending] = useState(false);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const chatWindowRef = useRef(null);
  const [showPromptOverlay, setShowPromptOverlay] = useState(false); // State to control prompt overlay
  const [prompts, setPrompts] = useState([]); // State to store prompts
  const [streamingResponse, setStreamingResponse] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const timeoutRef = useRef(null);

  const [status, setStatus] = useState(null);

  const location = useLocation(); // Get the current location object
  const queryParams = new URLSearchParams(location.search); // Parse the query string
  const theme = queryParams.get('theme'); // Extract the `theme` parameter if it exists

  // Get the id and session_id parameter from the URL
  const { id, session_id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoader(true);
      let response;
      try {
        if (id) {
          response = await fetchAssistantWithAssistantId(id);
        }
        setWelcomeMessage();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoader(false);
      }
    };

    fetchData();
  }, [id, session_id]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedAssistant) {
      fetchStatus();
    }
  }, [selectedAssistant]);

  useEffect(() => {
    if (status === 'DONE') {
      fetchSessionGridData(session_id, selectedAssistant);
    }
  }, [status]);

  /**
   * Resets the chat to its initial state.
   */
  const handleResetChat = async () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setStatus(null);
    setIsLoader(false);
    setLoadingData(true);
    setSending(false);
    setMessages([]);
    setNewMessage('');
    setShowPromptOverlay(false);
    setWelcomeMessage();
    addProgressMessage('10', 'Initializing...');
    setStatus('IN_PROGRESS');
    await fetchStatus();
  };

  // Helper function to remove grid data from the messages
  const removeGridDataMessages = () => {
    setMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.type !== 'Grid')
    );
  };

  /**
   * Set Welcome Message.
   */
  const setWelcomeMessage = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: 'assistant',
        text: CUSTOM_AGENT_WELCOME_MESSAGE,
      },
    ]);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleStreamingResponse = () => {
    setStreamingResponse(!streamingResponse); // Toggle includeFiles state
  };
  /**
   * Fetches the list of assistants.
   */
  const fetchAssistantWithAssistantId = async ($id) => {
    setIsLoader(true);
    try {
      const response = await getAssistantByAssistantId($id);
      if (response?.isSuccess) {
        setAssistants([...assistants, response?.data]);
        setSelectedAssistant(response?.data);
      } else {
        GenerateNotification(
          buildNotification({
            title: 'Error fetching assistants',
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
      return response;
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error fetching assistants: ' + error,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setIsLoader(false);
    }
  };

  /**
   * Submits the message on key press (Enter).
   * @param {Object} event - Event object.
   */
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!sending && !loadingData) {
        handleMessageSend();
      }
    }
  };

  /**No data available!


   * Handles the selection of a prompt and hides the prompt overlay.
   * @param {string} prompt - Selected prompt.
   */
  const handlePromptSelection = (prompt) => {
    if (!sending) {
      handleMessageSend(prompt);
      setShowPromptOverlay(false);
    }
  };

  // Helper function to add a progress message
  const addProgressMessage = (progress, text, error = false) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: 'assistant',
        type: 'progress',
        progress,
        text,
        error,
      },
    ]);
  };

  // Helper function to remove progress messages
  const removeProgressMessages = () => {
    setMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.type !== 'progress')
    );
  };

  const fetchSessionGridData = async (id, data = null) => {
    setLoadingData(true);
    removeGridDataMessages();
    setStatus('LOADING_GRID_DATA');
    // Show initial progress message
    addProgressMessage(90, 'Loading data...');

    try {
      const url = data?.data ? JSON.parse(data?.data)?.url || null : null;
      const response = await getSessionGridData(id, url);

      // Remove progress message
      removeProgressMessages();

      if (response?.error) {
        throw new Error(response.error);
      }

      // Show completion progress
      addProgressMessage(100, 'Data loaded successfully!');

      // Delay to allow user to see the completion message
      setTimeout(() => {
        removeProgressMessages();
        // Add grid data to chat
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: 'assistant',
            type: 'Grid',
            text: '',
            data: {
              metadata: response.metadata || {},
              values: response.values || [],
            },
          },
        ]);

        // Display suggested prompts if available
        if (response.questions?.length) {
          setPrompts(response.questions);
          setShowPromptOverlay(true);
        }

        setLoadingData(false);
      }, 2000);
    } catch (error) {
      // Remove progress message and show error
      removeProgressMessages();
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          type: 'error',
          text: 'Error fetching data, please try again later.',
        },
      ]);

      // Show error notification
      GenerateNotification(
        buildNotification({
          title: `Error fetching Grid Data: ${error.message || error}`,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setStatus('LOADING_GRID_DATA_DONE');
    }
  };
  /**
   * Handles selection of an assistant.
   * @param {Object} event - Event object.
   */
  const handleAssistantSelect = async (event) => {
    const assistant = assistants.find(
      (assistant) => assistant.id === parseInt(event)
    );
    handleResetChat();
    setSelectedAssistant(assistant);
  };

  /**
   * Handles sending of a message.
   */
  const handleAssistantNotSelected = () => {
    if (!selectedAssistant || selectedAssistant.id === '') {
      GenerateNotification(
        buildNotification({
          title: 'Please select an assistant!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
      return true;
    }
    return false;
  };

  const handleCustomMessageFlow = async (prompt) => {
    if (handleAssistantNotSelected()) return;
    const trimmedNewMessage = newMessage.trim();
    const trimmedPrompt = Array.isArray(prompt) ? prompt[0] : prompt.trim();
    if (trimmedNewMessage.length === 0 && trimmedPrompt.length === 0) return;
    setShowPromptOverlay(false);
    const message = trimmedNewMessage ? trimmedNewMessage : trimmedPrompt;
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', text: message },
    ]);
    setNewMessage('');
    setSending(true);

    const data = {
      command: message,
      session_key: session_id,
    };
    const url = selectedAssistant?.data
      ? JSON.parse(selectedAssistant.data)?.url || null
      : null;
    const response = await sendBYMessage(data, url);
    setSending(false);
    if (response && response?.status_code === 200) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          text: response?.message,
          type: response?.type,
          data: response?.payload,
          attachment: response?.attachemnt,
        },
      ]);
      setSending(false);
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          text: response.message ?? 'There is some error!',
          type: response?.type,
        },
      ]);
      setSending(false);
    }
  };

  const handleMessageSend = async (message = '') => {
    if (selectedAssistant?.type === 'Custom') {
      await handleCustomMessageFlow(message);
    }
  };

  /**
   * Scrolls to the bottom of the chat window.
   */
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  const fetchStatus = async () => {
    try {
      const url = selectedAssistant?.data
        ? JSON.parse(selectedAssistant.data)?.url || null
        : null;
      const response = await getStatus(session_id, url); // Pass the URL to getStatus
      if (response?.error) {
        // Handle error response
        removeProgressMessages();
        addProgressMessage(
          response?.progress,
          'There was an error. Please try again later!'
        );
        setStatus(null);
      } else {
        // Update status
        setStatus(response?.status);
        removeProgressMessages();
        addProgressMessage(response?.progress, response?.description);

        if (response?.status === 'DONE') {
          // Clear status and progress messages when done
          setStatus(null);
          removeProgressMessages();
          setStatus('DONE');
        }
      }

      // Schedule the next fetch if status is IN_PROGRESS, NEW, or in case of an error
      if (response?.status === 'IN_PROGRESS' || response?.status === 'NEW') {
        timeoutRef.current = setTimeout(fetchStatus, 2000);
      }
    } catch (error) {
      setLoadingData(false);
      setStatus(null);
      console.error('Error fetching status:', error);
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div
        className={`bot-chat-container smart-custom-bot k-bg-white ${
          theme === 'blueyonder' ? 'blue-yonder-theme' : 'custom-theme'
        }`}
      >
        <React.Suspense fallback={<Loader />}>
          {isLoader && <Loader />}
          {selectedAssistant?.name && (
            <Card className="assistant-selection k-mt-1">
              <div className="k-d-flex k-justify-content-between custom-bot-header">
                <p>
                  <span className="assistant-name k-display-flex k-align-items-center">
                    {selectedAssistant?.name && assistants.length > 1 && (
                      <>
                        <select
                          className="custom-select k-ml-1"
                          value={selectedAssistant.id}
                          onChange={(event) =>
                            handleAssistantSelect(event.target.value)
                          }
                        >
                          {assistants.map((assistant, index) => (
                            <option key={assistant.id} value={assistant.id}>
                              {assistant.name}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                    {selectedAssistant?.name && assistants.length <= 1 && (
                      <span className="selected-assistant assistant-name-custom">
                        {selectedAssistant.name}
                      </span>
                    )}
                    {((!sending && !loadingData) || status == null) && (
                      <span
                        className="k-button-icon k-font-icon k-i-arrow-rotate-cw-small !k-font-size-xl delete-button k-cursor-pointer reset-button k-ml-1"
                        onClick={handleResetChat}
                      />
                    )}
                  </span>
                </p>
                {(selectedAssistant?.type === 'OpenAI' ||
                  selectedAssistant?.type === 'SmartAI') && (
                  <div className="streaming-response-switch k-mb-2 k-mr-2 k-float-right">
                    <span className="k-mr-2 k-font-size-sm">Streaming</span>
                    <Switch
                      checked={streamingResponse}
                      onChange={handleStreamingResponse}
                    />
                  </div>
                )}
              </div>
            </Card>
          )}
          <Card className="chat-box">
            <div className="bot-chat-window" ref={chatWindowRef}>
              {messages &&
                messages.slice(0).map((message, index) => (
                  <div
                    key={index}
                    className={`message ${message.role === 'user' ? 'user-message' : 'assistant-message'} ${message.type ? ` otherdata ${message.type}` : ''}`}
                  >
                    {message?.type === 'Grid' ? (
                      // Render table if the type is Grid
                      <>
                        {message?.text && <p>{message?.text}</p>}
                        <GridComponent
                          metadata={message?.data?.metadata}
                          values={message?.data?.values}
                        />
                      </>
                    ) : message.type === 'Pie_Chart' ? (
                      // Render pie chart if the type is PieChart
                      <>
                        <p>{message?.text}</p>
                        <PieChartComponent
                          metadata={message?.data?.metadata}
                          values={message?.data?.values}
                        />
                      </>
                    ) : message.type === 'Chart' ? (
                      <>
                        <p>{message?.text}</p>
                        <ChartImageComponent
                          imageContent={message?.attachment?.content}
                          imageType={message?.attachment?.type}
                        />
                      </>
                    ) : message.type === 'progress' &&
                      (status === 'IN_PROGRESS' ||
                        status === 'NEW' ||
                        status === 'LOADING_GRID_DATA' ||
                        status === 'LOADING_GRID_DATA_DONE') ? (
                      // Render progress card if the type is progress
                      <ProgressCard
                        progress={message.progress}
                        text={message.text}
                        error={message.error}
                      />
                    ) : (
                      // Otherwise, render the message content
                      <FormatMessageContent markdownContent={message.text} />
                    )}
                  </div>
                ))}
              {showPromptOverlay && (
                <div className="prompt-cards">
                  {prompts.map((prompt, index) => (
                    <div
                      key={index}
                      className="prompt-card"
                      onClick={() => handlePromptSelection(prompt)}
                    >
                      <span>{prompt}</span>
                      <button className="delete-button k-float-right k-cursor-pointer">
                        <span className="fas fa-paper-plane" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {sending && <div className="custom-dots k-ml-3" />}
            <div className="bot-message-input">
              <input
                type="text"
                value={newMessage}
                onKeyPress={handleKeyPress}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
                disabled={
                  sending ||
                  status === 'IN_PROGRESS' ||
                  status === 'NEW' ||
                  status === 'LOADING_GRID_DATA' ||
                  (status !== 'null' && status !== 'LOADING_GRID_DATA_DONE')
                }
              />
              <Button
                className="ai-button-primary"
                onClick={() => {
                  if (!sending && !loadingData) {
                    handleMessageSend();
                  }
                }}
                disabled={
                  sending ||
                  status === 'IN_PROGRESS' ||
                  status === 'NEW' ||
                  status === 'LOADING_GRID_DATA' ||
                  (status !== 'null' && status !== 'LOADING_GRID_DATA_DONE')
                }
              >
                {!sending ? (
                  <span className="fas fa-paper-plane" />
                ) : (
                  <span className="k-text-white">...</span>
                )}
              </Button>
            </div>
          </Card>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};

export default SmartCustomBot;
