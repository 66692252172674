import { BYApiCall } from '../../../Utils/Api/Api';

/**
 * Send a message and get the reply.
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 * @param data
 * @param custom_url
 */
const sendBYMessage = async (data, custom_url = null) => {
  try {
    const response = await BYApiCall(
      '/warehouse/chat',
      'POST',
      data,
      custom_url
    );
    return response;
  } catch (error) {
    return {
      message: 'There is some thing wrong Please try again!',
      error: true,
    };
  }
};

/**
 * get grid Data .
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 * @param session_id
 * @param custom_url
 */
const getSessionGridData = async (session_id, custom_url = null) => {
  try {
    const data = null;
    const response = await BYApiCall(
      `/warehouse/session/${session_id}/data?question_suggestion=true`,
      'GET',
      data,
      custom_url
    );
    return response;
  } catch (error) {
    return {
      message: 'There is some thing wrong Please try again!',
      error: true,
    };
  }
};
/**
 * Initiate Login Request
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 * @param data
 */
const initiateLoginRequest = async (data) => {
  try {
    const response = await BYApiCall('/warehouse/chat', 'POST', data);
    return response;
  } catch (error) {
    return {
      message: 'There is some thing wrong Please try again!',
      error: true,
    };
  }
};

/**
 * Initiate Login Request
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 * @param session_key
 * @param custom_url
 */
const getStatus = async (session_key, custom_url = null) => {
  try {
    const response = await BYApiCall(
      `/warehouse/session/${session_key}/status`,
      'GET',
      null,
      custom_url
    );
    return response;
  } catch (error) {
    return {
      message: 'There is some thing wrong Please try again!',
      error: true,
    };
  }
};

/**
 * Login Request
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 * @param data
 */
const BYLogin = async (data) => {
  try {
    const response = await BYApiCall('/warehouse/login', 'POST', data);
    return response;
  } catch (error) {
    return {
      message: 'There is some thing wrong Please try again!',
      error: true,
    };
  }
};

export {
  sendBYMessage,
  initiateLoginRequest,
  BYLogin,
  getSessionGridData,
  getStatus,
};
